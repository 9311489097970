import * as React from "react";
import { PageProps } from "gatsby";
import Layout from "../components/layout";
import Container from "../components/container";

const GalleryPage: React.VFC<PageProps> = () => {
  return (
    <div className="flex flex-col items-center w-screen h-screen">
      <Layout title={`Gallery`}>
        <Container className="flex-1 flex items-center justify-center px-4">
          <h1 className="header-text text-white">Coming Soon!</h1>
        </Container>
      </Layout>
    </div>
  );
};

export default GalleryPage;
